<template>
  <div class="swiper-container" id="unit">
    <div class="swiper-wrapper">
      <div class="swiper-slide" v-for="(sublist, i) in groupList" :key="i">
        <div class="box" v-for="(item, j) in sublist" :key="j">
          <a class="item"  :href="item.linkAddress" target="_blank">
            <el-image :src="item.pictureUrl" class="img"/>
          </a>
        </div>
      </div>
    </div>
    <div class="swiper-pagination"></div>
  </div>
</template>

<script>
import Swiper from 'swiper'
import 'swiper/dist/js/swiper'
import 'swiper/dist/css/swiper.css'
export default {
  name: 'mySwiper',
  props: {
    homeBanner: Array
  },
  data() {
    return {
      // imgUrl: require('../static/img/jieshao.png'),
      // imgList: ["", ""]
    }
  },
  computed: {
	  groupList() {
		  var list = [], step = 8;
		  // 4个为一组,分组
		  for (var i = 0; i < this.homeBanner.length; i++) {
		  	const item = this.homeBanner[i]
        if (i%step == 0){
          list.push([item])
        } else {
          const index = Math.floor(i/step)
          list[index].push(item)
        }
		  }
      console.log(list)
      
    
		  return list
		  
	  },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.$nextTick(() => {
        let s = new Swiper('#unit', {
          paginationClickable: true,
          speed: 2000,
          loop: false,
          observer: true,
          observeParents: true,
          autoplayDisableOnInteraction: false,
          autoplay: false,
          navigation: { 
            nextEl: '.page-spe-next',
            prevEl: '.page-spe-prev'
          },
          pagination: {
            el: '.swiper-pagination'
          }
        })
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.swiper-container {
  width: 100%;
  height: 130px;
  .swiper-slide {
    display: flex;
    .box {
      width: 12.5%;
      display: flex;
      justify-content: center;
    }
    .item {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      .img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
  /deep/.swiper-pagination-bullet-active {
    background: #ffc900!important;
  }
}

</style>
