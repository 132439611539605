<template>
  <div class="body-content">
    <banner v-if="bannerList && bannerList.length > 0" :homeBanner="bannerList"></banner>
    <div class="fast-check-block">
      <!-- <div class="title">
        <span class="h1 light-blue">快速检索</span>
        <div class="search">
          <el-input
            placeholder="请输检索关键字"
            prefix-icon="el-icon-search"
            @keyup.enter.native="handleSearch"
            v-model="fastCheckSearchVal"
          ></el-input>
          <div class="search-btn" @click="handleSearch">搜索</div>
        </div>
      </div> -->
      <div class="content bg-rgba-w-1">
        <div class="left">
          <div class="field">
            <el-select v-model="val1" placeholder="-大科学装置/大型仪器-" @change="bigTypeChange" clearable>
              <el-option key="1" label="大科学装置" value="1"></el-option>
              <el-option key="2" label="大型仪器" value="2"></el-option>
            </el-select>
          </div>
          <div class="field">
            <el-select v-model="val2" placeholder="-仪器类别-" @change="smallTypeChange" clearable>
              <el-option v-for="item in typeOptions" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </div>
          <div class="field">
            <el-select v-model="val3" placeholder="-仪器名称-">
              <el-option v-for="item in deviceOptions" :key="item.id" :label="item.deviceName || item.instrumentName"
                :value="item.deviceName || item.instrumentName"></el-option>
            </el-select>
          </div>
          <div class="field">
            <div class="search-btn" @click="handleSearch(1)">查询</div>
          </div>
        </div>
        <div class="right">
          <div class="item" v-for="(item, index) in fastList" :key="index">
            <img :src="item.imgUrl" class="img" />
            <div class="text">
              <div>
                <span class="name">{{ item.number }}</span>
                <span class="desc">{{ item.unit }}</span>
              </div>
              <span class="desc">{{ item.desc }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tabs-block">
      <div class="left bg-rgba-w-1">
        <div class="title">
          <span :class="{ 'tab-name': true, 'h1 light-blue': activeNotice == 1 }" @click="(activeNotice = 1)">通知公告</span>
          <span :class="{ 'tab-name': true, 'h1 light-blue': activeNotice == 2 }" @click="(activeNotice = 2)">政策文件</span>
          <div class="more" @click="navigateTo('/sharedInstrument/notice')">更多>></div>
        </div>
        <div class="content">
          <div class="notice" v-for="(item, index) in announcementMap[activeNotice]" :key="index"
            @click="navigateTo('/sharedInstrument/notice/detail', item.id)">
            <div class="time h3">{{ formatTime(item) }}</div>
            <div class="text">
              <div class="h3">{{ item.title }}</div>
              <div class="detail">{{ subContent(item.contentText) }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="right">
        <el-popover placement="right" width="160" trigger="click">
          <div class="center">
            <img src="../../static/images/share/wx1.jpg" width="150" height="150" />
            <div>联系人:云上科技城</div>
            <div>
              <i class="el-icon-message"></i>
              <span style="font-size: 14px;">daiml2009@qq.com</span>
            </div>
          </div>
          <div class="link bg-rgba-w-1" style="margin-bottom: 40px;" slot="reference">
            <img class="img" :src="tabsRightLink[0].imgUrl" />
            <span class="h1">{{ tabsRightLink[0].name }}</span>
          </div>
        </el-popover>
        <div class="link bg-rgba-w-1" @click="tabsRightLinkClicked()">
          <img class="img" :src="tabsRightLink[1].imgUrl" />
          <span class="h1">{{ tabsRightLink[1].name }}</span>
        </div>
      </div>
    </div>
    <pic-list title="大科学装置共享" :topLinks="topLinks1" :dataList="deviceMap" type="0"
      path="/sharedInstrument/equipment"></pic-list>
    <pic-list title="大型仪器共享" :topLinks="topLinks2" :dataList="instrumentMap" type="1"
      path="/sharedInstrument/instrument"></pic-list>
    <pic-list title="仪器商城" :topLinks="topLinks3" :dataList="marketMap" type="3"
      path="/sharedInstrument/market"></pic-list>
    <div class="other-block">
      <div class="title">
        <span class="h1 light-blue">仪器研发</span>
        <div class="more" @click="navigateTo('/sharedInstrument/development')">更多>></div>
      </div>
      <div class="content bg-rgba-w-1">
        <img class="img" src="../../static/images/share/8.png" />
        <div class="right">
          <div class="h2 light-blue pointer" @click="navTo1">{{ instrumentRd.instrumentName }}</div>
          <div>
            <div class="text">
              <span class="light-blue">发起单位</span>
              <span>:{{ instrumentRd.initiateUnit }}</span>
            </div>
            <div class="text">
              <span class="light-blue">公关难点</span>
              <span>:{{ instrumentRd.difficultPoint }}</span>
            </div>
            <div class="text">
              <span class="light-blue">项目意义</span>
              <span>:{{ instrumentRd.projectSignificance }}</span>
            </div>
            <div class="text overflow-hidden-4">
              <span class="light-blue">项目详情</span>
              <span>:{{ instrumentRd.projectDetail }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="other-block">
      <div class="title">
        <span class="h1 light-blue">共建单位</span>
        <!-- <div class="more" @click="navigateTo('/sharedInstrument/friends')">更多>></div> -->
      </div>
      <div class="pic-list">
        <swiper :homeBanner="unitList"></swiper>
      </div>

    </div>
  </div>
</template>

<script>
import Banner from '../../components/banner.vue';
import PicList from '../../components/picList.vue';
import Swiper from '../../components/swiper.vue';
export default {
  name: 'share-index',
  components: {
    Banner,
    PicList,
    Swiper
  },
  data() {
    return {
      pageTopPosition: 0,
      bannerList: [],
      fastCheckSearchVal: null,
      typeOptions: [],
      type1: [
        {
          id: 1,
          name: '离心试验'
        },
        {
          id: 2,
          name: '振动试验'
        },
        {
          id: 3,
          name: '风洞试验'
        },
        {
          id: 4,
          name: '高空台试验'
        },
        {
          id: 5,
          name: '冲击试验'
        },
        {
          id: 6,
          name: '星光激光装置试验'
        }
      ],
      type2: [
        {
          id: 1,
          name: '电子仪器'
        },
        {
          id: 2,
          name: '工程检测'
        },
        {
          id: 3,
          name: '软件测试'
        },
        {
          id: 4,
          name: '计量校准服务'
        },
        {
          id: 5,
          name: '材料检测'
        },
        {
          id: 6,
          name: '其他服务'
        }
      ],
      deviceOptions: [],
      val1: null,
      val2: null,
      val3: null,
      fastList: [
        {
          number: '',
          unit: '项',
          desc: '服务产品',
          imgUrl: require('../../static/images/share/7.png'),
          code: 'product'
        },
        {
          number: '',
          unit: '台',
          desc: '科学仪器',
          imgUrl: require('../../static/images/share/1@2x.png'),
          code: 'instrument'
        },
        {
          number: '',
          unit: '家',
          desc: '共建单位',
          imgUrl: require('../../static/images/share/9.png'),
          code: 'unit'
        },
        {
          number: '',
          unit: '亿',
          desc: '仪器总价值',
          imgUrl: require('../../static/images/share/2@2x.png'),
          code: 'totalValue'
        },
        {
          number: '',
          unit: '次',
          desc: '累积服务',
          imgUrl: require('../../static/images/share/8 (2).png'),
          code: 'serviceTimes'
        },
        {
          number: '',
          unit: '项',
          desc: '年使用机时',
          imgUrl: require('../../static/images/share/31@2x.png'),
          code: 'annualMachineHours'
        }
      ],
      activeNotice: 1,
      announcementMap: {},
      tabsRightLink: [
        {
          name: '在线咨询',
          imgUrl: require('../../static/images/share/3@2x.png'),
          path: ''
        },
        {
          name: '下载专区',
          imgUrl: require('../../static/images/share/4@2x.png'),
          path: ''
        }
      ],
      topLinks1: [
        {
          name: '离心试验',
          code: 1
        },
        {
          name: '振动试验',
          code: 2
        },
        {
          name: '风洞试验',
          code: 3
        },
        {
          name: '高空台试验',
          code: 4
        }, {
          name: '冲击试验',
          code: 5
        },
        {
          name: '星光激光装置试验',
          code: 6
        }
      ],
      deviceMap: {},
      topLinks2: [
        {
          name: '电子仪器',
          code: 1
        },
        {
          name: '工程检测',
          code: 2
        },
        {
          name: '软件测试',
          code: 3
        },
        {
          name: '计量校准服务',
          code: 4
        }, {
          name: '材料检测',
          code: 5
        },
        {
          name: '其他服务',
          code: 6
        }
      ],
      instrumentMap: {},
      topLinks3: [
        {
          name: '热门仪器',
          code: 1
        },
        {
          name: '二手仪器',
          code: 2
        },
        {
          name: '租赁仪器',
          code: 3
        },
        {
          name: '其他',
          code: 4
        }
      ],
      marketMap: {},
      unitList: [
        {
          imgUrl: require('../../static/images/share/47.png'),
          url: ''
        },
        {
          imgUrl: require('../../static/images/share/48.png'),
          url: ''
        },
        {
          imgUrl: require('../../static/images/share/49.png'),
          url: ''
        },
        {
          imgUrl: require('../../static/images/share/50.png'),
          url: ''
        },
        {
          imgUrl: require('../../static/images/share/51.png'),
          url: ''
        },
        {
          imgUrl: require('../../static/images/share/52.png'),
          url: ''
        },
        {
          imgUrl: require('../../static/images/share/53.png'),
          url: ''
        },
        {
          imgUrl: require('../../static/images/share/54.png'),
          url: ''
        }
      ],
      instrumentRd: {},
      setting: {}
    }
  },
  created() {
    // 调用工具方法示例
    this.index()
    this.typeOptions = this.type1;
  },
  beforeRouteLeave(to, from, next) {
    this.pageTopPosition = document.getElementById('app').scrollTop
    next()
  },
  activated() {
    document.getElementById('app').scrollTop = this.pageTopPosition
  },
  methods: {
    tabsRightLinkClicked(item) {
      // if (item.name == '在线咨询') {
      //   window.open('https://106193.kefu.easemob.com/webim/im.html?configId=e1a4a75d-7403-4c2c-9236-d320421b8d68', 'target');
      // }
      // else if (item.name == '下载专区') {
      //   this.$router.push({ path: '/sharedInstrument/yiqiyanfa', query: {} })
      // }
      this.$router.push({ path: '/sharedInstrument/yiqiyanfa', query: {} })
    },
    index() {
      this.$api.share.index().then((res) => {
        let data = res.data.data;
        this.bannerList = data.bannerList
        this.announcementMap = data.announcementMap
        this.deviceMap = data.deviceMap
        this.instrumentMap = data.instrumentMap
        this.marketMap = data.marketMap
        this.instrumentRd = data.instrumentRd || {}
        this.unitList = data.unitList
        for (let k in data.setting) {
          this.fastList.forEach((item) => {
            if (item.code == k) {
              item.number = data.setting[k]
            }
          })
        }
      })
    },
    navigateTo(url, item) {
      this.$router.push({ path: url, query: { id: item, notice: this.activeNotice } })
    },
    bigTypeChange(val) {
      this.val2 = null
      this.val3 = null
      if (!val) {
        this.typeOptions = []
        this.deviceOptions = []
        return
      } else {
        this.typeOptions = val === '1' ? this.type1 : this.type2;
      }
      let params = {
        type: 1,
        deviceType: null,
        instrumentType: null
      }
      this.$api.share.getInstrumentList(params).then((res) => {
        this.deviceOptions = res.data.data || [];
      })
    },
    smallTypeChange(val) {
      this.val3 = null
      if (!this.val2 || !this.val1) {
        this.deviceOptions = []
      } else {
        // this.deviceOptions = list 
        let params = {}
        if (this.val1 == '1') {
          params = {
            type: 1,
            deviceType: this.val2,
            instrumentType: null
          }
        } else {
          params = {
            type: 2,
            deviceType: null,
            instrumentType: this.val2
          }
        }
        this.$api.share.getInstrumentList(params).then((res) => {
          this.deviceOptions = res.data.data || [];
        })
      }
    },
    handleSearch(t) {
      console.log("this.val3=========", this.val3)
      let query = {
        keywords: t == 1 ? this.val3 : this.fastCheckSearchVal,
        type: this.val1
      }
      this.$router.push({ path: '/sharedInstrument/search', query: query })
    },
    subContent(str) {
      let s = str ? str.substring(0, 98) : ""
      return s.length > 90 ? s + '...' : s
    },
    formatTime(item) {
      let s = null;
      if (item.time) {
        s = item.time.indexOf(" ") ? ((item.time).split(" "))[0] : item.time
      } else {
        s = item.createTime.indexOf(" ") ? ((item.createTime).split(" "))[0] : item.createTime
      }
      return s;
    },
    navTo1(t) {
      this.$router.push({ path: '/sharedInstrument/instrumentDetail', query: { id: this.instrumentRd.id, title: '仪器研发', type: 4 } })
    },
    navTo2(item) {
      this.$router.push({ path: '/sharedInstrument/fDetail', query: { id: item.id } })
    }
  }
}
</script>

<style scoped lang="less">
.fast-check-block {
  padding: 10px 0;

  .title {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;

    .search {
      display: flex;

      .search-btn {
        background: #ffb001;
        color: #fff;
        font-size: 18px;
        text-align: center;
        line-height: 40px;
        border-radius: 5px;
        cursor: pointer;
        width: 80px;
      }
    }
  }

  .content {
    padding: 40px;
    display: flex;

    .left {
      width: 300px;

      .field {
        padding: 5px 0;

        /deep/.el-select {
          width: 100%;
        }

        .search-btn {
          background: #ffb001;
          color: #fff;
          font-size: 20px;
          text-align: center;
          line-height: 42px;
          border-radius: 5px;
          cursor: pointer;
        }
      }
    }

    .right {
      flex: 1;

      .item {
        display: inline-flex;
        width: 33%;
        padding: 20px 0;
        justify-content: center;

        .img {
          width: 56px;
          height: 56px;
          // border-radius: 50%;
          margin-right: 20px;
          // cursor: pointer;
        }

        .text {
          // cursor: pointer;
          text-align: left;
          min-width: 130px;
        }

        .name {
          font-size: 34px;
        }

        .desc {
          font-size: 16px;
        }
      }
    }
  }
}

.tabs-block {
  display: flex;
  margin-top: 40px;

  .left {
    flex: 1;
    margin-right: 50px;

    .title {
      position: relative;
      padding: 20px;

      .tab-name {
        padding: 5px 40px 5px 10px;
        font-size: 18px;
        cursor: pointer;
      }

      .more {
        position: absolute;
        bottom: 20px;
        right: 20px;
        cursor: pointer;
      }
    }

    .content {
      padding: 0 20px;

      .notice {
        display: flex;
        font-size: 18px;
        cursor: pointer;

        .time {
          width: 110px;
          padding-top: 2px;
        }

        .text {
          flex: 1;

          .detail {
            padding: 14px 0;
            line-height: 2;
            font-size: 14px;
          }
        }
      }

      .notice:hover {
        color: aqua;
      }
    }
  }

  .right {
    .link {
      width: 340px;
      padding: 40px 50px;
      box-sizing: border-box;

      display: flex;
      align-items: center;
      cursor: pointer;

      .img {
        width: 60px;
        height: 60px;
        margin-right: 20px;
        border-radius: 50%;
      }
    }

    // .link:last-child {
    //   margin-bottom: 0;
    // }
  }
}

.other-block {
  margin-top: 40px;

  .title {
    position: relative;
    border-bottom: 2px solid rgba(255, 255, 255, 0.1);
    padding-bottom: 10px;

    .more {
      position: absolute;
      bottom: 10px;
      right: 20px;
      cursor: pointer;
    }
  }

  .overflow-hidden-4 {
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    /* 这里是超出几行省略 */
    overflow: hidden;
  }

  .content {
    margin-top: 20px;
    padding: 20px;
    box-sizing: border-box;
    display: flex;

    img {
      width: 700px;
      height: auto;
      margin-right: 20px;
    }

    .h2 {
      padding-bottom: 5px;
    }

    .text {
      padding: 5px 0;
      line-height: 2;
    }
  }

  .pic-list {
    padding: 30px 0 0;
    margin-bottom: -30px;
  }
}

.pointer {
  cursor: pointer;
}

.center {
  text-align: left;
  font-size: 16px;
  font-weight: 700;

  i {
    font-weight: 700;
  }
}
</style>
